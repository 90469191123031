<template>
  <div class="ma-8"  >
    <div v-if="variant !== null">
      <div class="d-flex flex-row">
        <v-btn :disabled="variant.row_num == 0" text color="primary" class="ml-auto mr-4 my-auto" @click="onPrev()"><v-icon>mdi-chevron-double-left</v-icon> Prev</v-btn>
        <div class="d-flex flex-column">
            <div class="text-h4">{{ variant.detail.id }}</div>
            <div class="mx-auto text-h6"> {{ variant.sample.path }}
            <span v-if="label !== null"  :class=" label.color + '--text'">{{label.text}}</span>
            </div>
        </div>
        <v-btn text color="primary" class="mr-auto ml-3 my-auto" @click="onNext()">Next <v-icon>mdi-chevron-double-right</v-icon> </v-btn>
      </div>
      <div class="d-flex flex-row mt-6 flex-wrap">
        <div v-for="f in Object.keys(variant.detail)" :key="f" class="mr-8">
          <span class="font-weight-medium">{{ f }}: </span>{{ variant.detail[f] }}
        </div>
      </div>
      <div class="d-flex flex-row mt-6">
        <v-card elevation="0" class="pa-4" width="100%">
          <div class="d-flex flex-row mb-2">
            <div>
              <v-btn width="200px" color="red" class="white--text" @click="onApply(false)">DISCARD</v-btn>
              <div class="caption grey--text">Shortcut:  <v-icon>mdi-arrow-left-bold</v-icon> </div>
            </div>
            <div class="ml-auto d-flex flex-column">
              <v-btn width="200px" color="green" class="ml-auto white--text" @click="onApply(true)">CONFIRM</v-btn>
              <div class="ml-auto caption grey--text">Shortcut:  <v-icon>mdi-arrow-right-bold</v-icon> </div>

            </div>
          </div>
          <v-img v-if="imageData" :src="imageData" />
        </v-card>
      </div>
    </div>
    <div v-else class="mt-10 pt-10 text-center" style="width: 100%">
      <div class="text-h4 grey--text mb-4">THERE IS NO UNLABELED VARIANTS LEFT.</div>
      <div>
        <router-link to="/admin/manualcuration">Back to Samples</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { getVariantData, putVariantLabel, getVariantImage } from "@/api/curation.js";
export default {
  name: "Curation",
  data: () => ({
    variant: null,
    sampleId: null,
    imageData: null,
    // page: 1,
  }),
  created() {
    this.sampleId = this.$route.params.sampleId;
    this.loadVariantData();
    document.addEventListener('keydown',  this.onKeyDown)
  },
  destroyed(){
    document.removeEventListener('keydown',  this.onKeyDown)
  },
  computed:{
    label(){
        if(this.variant){
            const idx = this.variant.curated.findIndex(item => item.username == this.$store.state.Username)
            console.log(this.variant.id, idx, this.variant.curated,this.$store.state.Username)
            if(idx >= 0)
            {
                const lbl = this.variant.curated[idx].label
                if(lbl === true){
                    return {text: 'CONFIRMED',color:'green',icon:'mdi-check'}
                }else{
                    return {text: 'DISCARDED',color:'red',icon:'mdi-check'}
                } 
            }
        }
        return null
    }
  },
  methods: {
    onKeyDown(v){
      if(v.key=='ArrowRight'){
        this.onApply(true)
      } else if(v.key=='ArrowLeft'){
        this.onApply(false)
      }
      console.log(v)
    },
    onApply(label) {
      const _this = this;
      putVariantLabel(this.sampleId, this.variant.detail.id, label, `Variant marked as ${label ? "CONFIRM" : "DISCARD"}`, () => {
        _this.loadVariantData();
      });
    },
    onPrev() {
      this.loadVariantData(this.variant.row_num - 1);
    },
    onNext() {
      this.loadVariantData(this.variant.row_num + 1);
    },
    loadVariantData(row_num = null) {
      this.imageData = null;
      const _this = this;
      let params = {};
      if (row_num !== null) {
        params = {
          row_num: row_num,
        };
      } else {
        params = {
          page: 1,
          page_size: 1,
          curated: false,
        };
      }
      getVariantData(this.sampleId, params, (res) => {
        if (res.results.length > 0) {
          _this.variant = res.results[0];
          getVariantImage(
            _this.variant.id,
            (res) => {
              _this.imageData = URL.createObjectURL(res);
            },
            () => {
              _this.imageData = null;
            }
          );
          //   _this.page++;
        } else {
          _this.variant = null;
        }
      });
    },
  },
};
</script>
<style scoped>
</style>