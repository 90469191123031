import ApiIntegrface from "./api"

export function getCurationTaskStatus(callback, errhandler) {
    const api = new ApiIntegrface()
    const url = '/api/v1/tasks/extract_train_sample_data'
    api.get(url, null, callback, errhandler, null, null, false)

    // FAILURE
    // PENDING
    // RECEIVED
    // RETRY
    // REVOKED
    // STARTED
    // SUCCESS
    // callback({ status: 'SUCCESS' })
}
export function scanSamples(callback, successMessage) {
    const api = new ApiIntegrface()
    const url = '/api/v1/curation/scan'
    api.post(url, null, callback, null, successMessage , null, null)

}

export function getSamples(page = 1, page_size = 500, callback) {
    const api = new ApiIntegrface()
    const url = '/api/v1/curation/samples/'
    const params={
        page:page,
        page_size: page_size
    }
    api.get(url, params, callback, null, null, null, false)
}

export function getVariantData(sampleId, params, callback) {

    const api = new ApiIntegrface()
    const url = `/api/v1/curation/samples/${sampleId}/variants/`

    api.get(url, params, callback, null, null, null)

}

export function getVariantImage(variantId, callback, errhandler){
    const api = new ApiIntegrface()
    const url = '/api/v1/curation/samples/images/' + variantId 
    api.get(url, null, callback, errhandler, null, null, true, 'blob')
  
  }
  

export function putVariantLabel(sampleId, variantId, label, successMessage, callback){
    const api = new ApiIntegrface()
    const url = `/api/v1/curation/samples/${sampleId}/variants/${variantId}/labels/?label=${label}`
    api.put(url, null, callback, null, successMessage , null, null)
}

export function getCurationCsvResult(sampleId, callback, errhandler){
    const api = new ApiIntegrface()
    const url = '/api/v1/curation/samples/' + sampleId + '/download/' 
    api.get(url, null, callback, errhandler, null, null, true, 'blob')
  
  }
